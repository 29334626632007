import React from 'react';
 
import Navbar from './components/Navbar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
 
import Index from './pages/Index';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Convenios from './pages/Convenios'
import Sobre from './pages/Sobre'
import BancodeDados from './pages/BancodeDados'
import Eventos from './pages/Eventos'
import { FaleConosco } from './pages/FaleConosco'
import DivulgacaoCientifica from './pages/DivulgacaoCientifica'

import VIEvento from './components/eventos/VIEvento' 
import VEvento from './components/eventos/VEvento' 
import IVEvento from './components/eventos/IVEvento' 
import IIIEvento from './components/eventos/IIIEvento' 
import IIEvento from './components/eventos/IIEvento' 
import Adevolucao from './components/eventos/Adevolucao' 
import ISeminario from './components/eventos/ISeminario' 
import Paleografia from './components/eventos/Paleografia' 


import ArtigoI from './components/DivulgacaoCientifica/Artigos/ArtigoI'
import ArtigoII from './components/DivulgacaoCientifica/Artigos/ArtigoII'
import ArtigoIII from './components/DivulgacaoCientifica/Artigos/ArtigoIII'
import As_fabulas_de_Esopo from './components/DivulgacaoCientifica/Artigos/As_fabulas_de_Esopo'
import Ensinar_a_fe_crista_na_peninsula_Iberica_medieval from './components/DivulgacaoCientifica/Artigos/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval'
import Instituicoes_fe_e_producao_escrita from './components/DivulgacaoCientifica/Artigos/Instituicoes_fe_eproducao_e_scrita'

import Verbetes from './components/Verbetes/VerbetesIndex'
import VerbetesPDF from './components/Verbetes/VerbetesPDF'
  import AutoresIndex from './components/Autores/AutoresIndex'

  import Documentos from './components/DocumentosPdf/Documentos'
import DocumentosPdfView from './components/DocumentosPdf/DocumentosPdfView';

function App() {
  return (
    
    <div className="App">      
           <Navbar  />  
      <Router>
        <Switch>
         
          <Route path='/' exact component={Index} />
          <Route path='/Sobre' component={Sobre} />
          <Route path='/Convenios' component={Convenios} />
          <Route path='/BancodeDados' component={BancodeDados} />
          <Route path='/Eventos' component={Eventos} />
          <Route path='/FaleConosco' component={FaleConosco} />
          <Route path='/DivulgacaoCientifica' component={DivulgacaoCientifica}/>

<Route path='/Documentos' component={Documentos} />

           
          <Route path='/IVEvento' component={IVEvento} />
          <Route path='/IIEvento' component={IIEvento} />
          <Route path='/IIIEvento' component={IIIEvento} />
          <Route path='/Adevolucao' component={Adevolucao} />
          <Route path='/ISeminario' component={ISeminario} />
          <Route path='/Paleografia' component={Paleografia} />
          <Route path='/VEvento' component={VEvento} />
          <Route path='/VIEvento' component={VIEvento} />

          <Route path='/ArtigoI' component={ArtigoI} />
          <Route path='/ArtigoII' component={ArtigoII} />
          <Route path='/ArtigoIII' component={ArtigoIII} />
          <Route path='/As_fabulas_de_Esopo' component={As_fabulas_de_Esopo} />
          <Route path='/Ensinar_a_fe_crista_na_peninsula_Iberica_medieval' component={Ensinar_a_fe_crista_na_peninsula_Iberica_medieval} />
          <Route path='/Instituicoes_fe_e_producao_escrita' component={Instituicoes_fe_e_producao_escrita} />

           
           
          <Route path='/Verbetes/:id' component={Verbetes} />
          <Route path='/VerbetesPDF/:id' component={VerbetesPDF} />
         
          <Route path='/DocumentosPdfView/:id/:page' component={DocumentosPdfView} />

          
 {/* <Route path='/Autores' component={AutoresIndex} />   */}

 <Route path='/AutoresIndex/:id' component={AutoresIndex} />  

 

        </Switch>
      </Router>

     
      </div> 
  )
}
 
export default App;
