import React, { useEffect } from 'react';
import './ArtigoI.css';
import img1 from '../../../assets/img/Artigos/Instituicoesfeeproducaoescrita.png'

import Footer from '../../Footer';
import { colors } from '@material-ui/core';


const Instituicoes_fe_e_producao_escrita = () => {
    useEffect(() => {
        // Envia o evento de visualização de página para o Google Analytics
        window.gtag('config', 'G-T3KBNL1ZLF', {
            page_path: 'https://www.googletagmanager.com/gtag/js?id=G-T3KBNL1ZLF'
        });
    }, []);

    return (
        <div>
            <div class="TituloArtigoI">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Divulgação Científica</h1>
                    </div>
                </div>
            </div>


            

            <div className="containerDivulgacao">

            <h1 className="titleDivulgacao">
            Instituições, fé e produção escrita:
o tripé da formação dos reinos virtuosos
na Idade Média e na Moderna
        </h1><br></br>
        <p className="subtitleDivulgacao">
        Coletânea é um dos produtos coletivos produzidos pelo grupo de pesquisa “O ensino da fé cristã na península Ibérica (Sécs. XIV, XV e XVI)”            </p>
        <div className="text-contentDivulgacao">
        <p className="highlight-letter">
       
        </p>
</div>


                <div className="book-image">
                    <img src={img1} alt="Capa do Livro"
                        style={{ width: '100%', maxWidth: '800px', height: 'auto' }}
                    />
                     <p style={{ marginTop: '10px' }}>
                     A formação de reinos virtuosos (séculos XII ao XVIII)</p>
                     <p style={{ marginTop: '10px' }}>                   
Leandro Alves Teodoro e Ana Paula Tavares Magalhães Tacconi (Org.). Editora da Universidade Federal de Santa Catarina (UFSC).
422 páginas | R$ 60,80
    </p>
                </div>
                <main className="main-contentDivulgacao">
                     
                    <p className="subtitleDivulgacao"> </p> 
                    <div className="contentDivulgacao">
                         
                        </div>
                    <div className="contentDivulgacao">
                        <div className="book-image">
                        </div>
                        <div className="text-contentDivulgacao">
                            <p className="highlight-letter">
                           O livro <em>A formação de reinos virtuosos (séculos XII ao XVIII)</em>, publicado em julho deste ano, 
                           pela editora da Universidade Federal de Santa Catarina (UFSC), é um dos empreendimentos 
                           coletivos do projeto “O ensino da fé cristã na península Ibérica (sécs. XIV, XV e XVI)”, 
                           financiado pela Fundação de Amparo à Pesquisa do Estado de São Paulo (FAPESP), 
                 na modalidade Jovem Pesquisador. Organizado pelo historiador Leandro Alves Teodoro – responsável pelo 
                 projeto e professor de História Medieval na Faculdade de Ciências e Letras da 
                 Universidade Estadual Paulista (UNESP), Câmpus de Assis – e pela historiadora 
                 Ana Paula Tavares Magalhães Tacconi – professora de História Medieval na Faculdade de Filosofia, 
                 Letras e Ciências Humanas da Universidade de São Paulo (USP) –, o volume é fruto de um evento ocorrido, 
                 virtualmente, em outubro de 2020.
                             </p>
                            <p className="paragraph-content ">
                            A coletânea reúne 17 capítulos de pesquisadores oriundos de universidades brasileiras e estrangeiras, 
                            que discutem temas relacionados ao caráter virtuoso das monarquias da Idade Média e da Época Moderna, 
                            com um recorte espacial na Europa e nas Américas. O adjetivo “virtuoso” designa, como apontam os organizadores, 
                            as práticas honestas e benevolentes, em especial, as da moral cristã, direcionadas a produzir e a manter a harmonia social de uma dada comunidade de pessoas (no caso, os reinos).                
                            </p>
                            <p className="paragraph-content ">
                            Além da introdução, a obra está dividida em três partes, nas quais figuram os pilares que fundamentaram o 
                            virtuosismo desses reinados: instituições, fé e produção escrita. 
                            “O objetivo desta coletânea consiste em avaliar a importância desses andaimes para a sustentação 
                            da ‘coisa pública’, por se considerarem tanto instrumentos de governo quanto meios para fixação de comportamentos”, 
                            esclarecem Teodoro e Tacconi.
                            </p>
                        </div>
                    </div>

 
                    <div className="contentDivulgacao">
                        <div className="text-contentDivulgacao">
                            <div className="section-title">
                                <h2>INSTITUIÇÕES E LAÇOS DE PODER</h2>
                            </div>
                            <p className='paragraph-normal'>
                            A primeira parte do livro é dedicada ao quadro institucional e aos vínculos de poder. Os cinco artigos 
                            que compõem o bloco concentram-se em aspectos referentes ao reino português medieval e o moderno. 
                            Em “A construção do Portugal medievo: poder, território, reino” (cap. 1), Bernardo Vasconcelos e Sousa, 
                            membro do Instituto de Estudos Medievais (IEM) e docente da Universidade Nova de Lisboa (Portugal), 
                            mostra como o processo de formação e de consolidação de Portugal como monarquia independente, ao longo dos 
                            séculos XII e XV, foi complexo e multifacetado. A conquista de um território próprio, no século XIII; a 
                            afirmação do centralismo da Coroa, nos séculos XIII e XIV; bem como a existência de uma língua própria, 
                            em fins do século XIII, são alguns elementos apontados pelo autor que contribuíram, de forma convergente e 
                            articulada, para a constituição e viabilidade de Portugal como uma entidade política autônoma. 
                            A construção do reino luso também é objeto de reflexão no texto de Maria Filomena Coelho, docente da 
                            Universidade de Brasília (UnB), todavia por um viés jurídico-político. Em “O Estado ‘virtuoso’: corpos e 
                            pluralismo jurídico em Portugal (século XII ao XIII)” (cap. 2), a autora, tomando o conceito de “Estado” 
                            como um “tipo de ordem pública e de poder público”, observa que o “modelo de Estado”, no início da Primeira 
                            Dinastia portuguesa, baseou-se em lógicas jurídicas/políticas pluralistas, de ideal conservador.
                             </p>
                            <p className="paragraph-content">
                            No capítulo seguinte, “A Capela real como expressão do reino virtuoso: apontamentos a partir dos 
                            capelães-mores quatrocentistas portugueses” (cap. 3), Douglas Mota Xavier de Lima, docente da Universidade 
                            Federal do Oeste do Pará (Ufopa), faz algumas considerações sobre a Capela real em Portugal – órgão de culto 
                            cristão nas cortes medievais e lugar importante de manifestação do caráter sacerdotal do rei. 
                            O historiador apresenta a trajetória de nove capelães-mores, que atuaram entre os reinados 
                            de D. João I (1385- 1433) e D. Afonso V (1448-1481), ressaltando suas origens e participações em 
                            diversas esferas da política do reino português: diplomacia, guerra, 
                            cerimônias monárquicas e burocracia.
                            </p>
                        </div>
                    </div>

                    <div className="text-contentDivulgacao">
                        <p className="paragraph-content">
                        Já em época moderna, Ana Paula Torres Megiani, docente da Universidade de São Paulo (USP), 
                        em “Quando as virtudes saem às ruas: emblemas e alegorias das entradas régias e 
                        cortesãs dos Filipes em Lisboa (1581 a 1619)” (cap. 4), 
                        discute as entradas filipinas em Lisboa, no período de união das Coroas espanhola e 
                        portuguesa. Ao analisar o programa cenográfico de Filipe II (1527-1598) e Filipe III 
                        (1578-1621) em terras lusas, a autora conclui que, no plano da imagem, 
                        tanto tradições antigas como práticas renovadas foram mobilizadas para aproximar os dois 
                        reinos. Por fim, no último contributo dessa primeira parte, 
                        “Entre as sociabilidades “lícitas” e “ilícitas” dos reis de Portugal: espaços, vivências e 
                        intimidades nocturnas (segunda metade do século XVII ao século XVIII)” (cap. 5), 
                        Maria Paula Marçal Lourenço, docente da Universidade de Lisboa (Portugal), numa investigação de
                         temática pouco debatida, nos dá a conhecer as aventuras amorosas noturnas dos reis da 
                         dinastia de Bragança com suas consortes e amantes, assim como os espaços onde ocorriam 
                         esses encontros.
                         </p>
                    </div>
                    

                    <div className="text-contentDivulgacao">
                        <div className="section-title">
                            <h2>FÉ E DOUTRINA</h2>
                        </div>
                        <div className="text-contentDivulgacao">
                            <p className='paragraph-normal'>
                            Na segunda parte da coletânea, estão reunidos estudos cujo fio condutor baseia-se em termos que contemplam a 
                            fé e a doutrina católica. Ela inicia-se com o capítulo de Carolina Coelho Fortes, docente da Universidade 
                            Federal Fluminense (UFF), intitulado “Domingos de Gusmão e as monjas: considerações sobre a carta ao convento de 
                            Madrid (ca.1220)” (cap. 6). Nele, Fortes investiga o status da vida religiosa feminina, destacando certa 
                            liberdade atribuída por Domingos de Gusmão (1170-1221), 
                            fundador da Ordem dos Pregadores, às monjas dominicanas madrilenhas, o que muda depois da sua morte (1221)                            </p>

                            <div className="contentDivulgacao" >
                                 

                                <div className="text-contentDivulgacao">
                                    <p className="paragraph-content">
                                    O culto dos santos e suas relações com a toponímia, a memória e o poder monárquico são destaques 
                                    nos capítulos de Ana Paula Tavares Magalhães Tacconi, docente da Universidade de São Paulo (USP), 
                                    e de Renata Cristina de Sousa Nascimento, docente da Universidade Federal de Goiás (UFG), da 
                                    Universidade Estadual de Goiás (UEG) e da Pontifícia Universidade Católica de Goiás (PUC-GO). 
                                    Em “A cristandade e os mortos: o martírio e as relíquias dos mártires de Marrocos (Portugal, século XIII)”
                                     (cap. 7), Tacconi discorre sobre um relato hagiográfico, composto no século XVI, que narra o 
                                     martírio e a morte de cinco missionários franciscanos enviados ao norte da África três séculos antes.
                                      A historiadora examina as implicações da narrativa com a construção da monarquia portuguesa e com o 
                                      fortalecimento do mosteiro de Santa Cruz de Coimbra como centro de poder. 
                                      Também Nascimento, em “Espaços sublimes: a reinvenção de uma toponímia sagrada” (cap. 8), 
                                      ao tomar como objeto de investigação a Catedral de Oviedo (Astúrias), revela como a presença 
                                      de relíquias de santos e de vestígios sagrados na península Ibérica contribuiu para a 
                                      sacralização dos territórios e para a legitimação de narrativas políticas.
                                     </p>                                 
                                </div>
                                </div>
                                </div>
                            </div>

                            <div className="contentDivulgacao" >
                            <div className="text-contentDivulgacao">
                            <p className="paragraph-content">
                            Nos dois capítulos subsequentes, são levantadas questões que permeiam a boa vida e a boa morte em 
                            fins da Idade Média. Em “A adesão às regras para a boa vida e a vida boa no Portugal quatrocentista” 
                            (cap. 9), Susani Silveira Lemos França, docente da Universidade Estadual Paulista (UNESP), 
                            com base nos escritos de governantes e letrados portugueses do século XV, considera a aceitação das 
                            normas, nesse momento, menos como resultado da dominação ou do receio das pessoas e mais como efeitos de 
                            acordos e convicções individuais, pautados no bem e no prazer. Por sua vez, Letícia Gonçalves Alfeu de Almeida, 
                            pesquisadora da Universidade Estadual de Campinas (Unicamp), no texto “A meditação sobre a morte como esteio 
                            da devoção dos leigos (França, século XIV ao XV)” (cap. 10), examina as formas pelas quais os cristãos deviam 
                            se portar no leito de morte, assim como meditar em relação ao seu fim, à luz dos escritos em língua vulgar, 
                            produzidos no reino da França, nos séculos XIV e XV.
                                     </p>
                         <p className="paragraph-content">
                           No capítulo que fecha o segundo bloco do livro, “A disciplina da língua para a 
                           construção de um Portugal quinhentista virtuoso” (cap. 11), Leandro Alves Teodoro, 
                           docente da Universidade Estadual Paulista, faz uma análise acerca do controle das 
                           palavras, mentalizadas ou ditas, no contexto de Contrarreforma, sublinhando a 
                           disciplina da língua como um dos elementos importantes para a construção de um reino 
                           virtuoso.

                                  
</p>
</div>
                                </div>
                                                        
                                <div className="section-title">
                                <h2>ESCRITOS E OS SEUS MEIOS DE CIRCULAÇÃO</h2>
                                </div>  
                               
                                
                                <div className="contentDivulgacao" >
                                <div className="text-contentDivulgacao">  
                            
                             
                            <p className='paragraph-normal'>
                            No terceiro e último bloco do livro, são discutidas a produção escrita e as suas formas de circulação. 
                            Os usos éticos e espirituais como operadores de propaganda política nas crônicas ibéricas medievais são o 
                            cerne do estudo de Francisco José Díaz Marcilla, docente da Universidade Nova de Lisboa (Portugal). 
                            Em “A ética e a espiritualidade como propaganda política nas crónicas ibéricas da Baixa Idade Média” 
                            (cap. 12), o historiador identifica, entre outros pontos, que a justiça, em especial a justiça régia, 
                            foi um dos conceitos mais veiculados à ética cristã nesse tipo de gênero.
</p>
                            <p className="paragraph-content">
                            Em seguida, no capítulo intitulado “‘Theologia & contemplação’ na livraria de D. Teodósio I (†1563), 
                            quinto duque de Bragança” (cap. 13), Ana Isabel Buescu, docente da Universidade de Nova Lisboa (Portugal), 
                            apresenta-nos a fisionomia da livraria do duque Teodósio I (1510?-1563), considerada como uma das grandes 
                            coleções de livro da Europa renascentista. Ao examinar o inventário, a autora constata que a biblioteca 
                            ducal abrangeu diversos ramos do saber da época e seus representantes fundamentais.
                                </p>
</div></div>
                                <div className="text-contentDivulgacao">
                                <p className="paragraph-content">
                                Ainda no contexto do século XVI, Maria Emília Granduque José, pesquisadora da Universidade Estadual Paulista, 
                                em “Notas para uma escrita virtuosa dos reinos espanhóis (século XVI)” (cap. 14),
                                 analisa as formas pelas quais os cronistas quinhentistas pensaram e registraram a escrita da história 
                                 geral da Espanha. De acordo com a historiadora, ao mesmo tempo em que essas obras históricas cumpriam uma 
                                 função memorável – narravam, entre outros fatos, os grandes feitos virtuosos, políticos e militares dos 
                                 espanhóis –, também tinham o propósito de serem úteis e exemplares ao bem comum.
                                </p>
                                </div>
                             
                                <div className="text-contentDivulgacao">
                                <p className="paragraph-content">
                                As três últimas contribuições que fecham a coletânea concentram-se em questões relacionadas ao 
                                processo de conquista no Novo Mundo. Ana Carolina Machado de Souza, pesquisadora da Universidade Estadual 
                                de Campinas (Unicamp), em “Conflitos morais na crônica de Pedro de Feria (ca.1524 a 1588): a impunidade 
                                aos índios ‘idólatras’” (cap. 15), investiga as acusações de “idolatria” de indígenas na Nova Espanha,
                                 com ênfase num relato cronístico do frade dominicano Pedro de Feria, no qual, com base em um caso local, 
                                 denuncia a ausência de punição severa frente a tal comportamento. A autora fornece um cenário complexo a 
                                 respeito da evangelização nas Américas, evidenciando os conflitos presentes entre os poderes civil e religioso 
                                 de índios e espanhóis. Por sua parte, Luís Guilherme Assis Kalil, docente da Universidade Federal Rural do Rio 
                                 de Janeiro (UFRRJ), em “Os entraves para a predicação da fé cristã no Novo Mundo na <em>Peregrinación</em> de 
                                 Bartolomé Lorenzo, de José de Acosta (1586)” (cap. 16), salienta como a violência dos conquistadores 
                                 contra os indígenas foi interpretada como um empecilho para a atuação da Coroa espanhola e de membros da 
                                 Igreja Católica em terras americanas pelo jesuíta José de Acosta (1540-1600).                              
                                  </p>
                                </div>

                             
                                
                                <div className="contentDivulgacao" >
                                <div className="text-contentDivulgacao">  
                                <p className="paragraph-content">
                                Por fim, Anderson Roberti dos Reis, docente da Universidade Federal de Mato Grosso, 
                                em “Um humanista decifra o México: os diálogos latinos de 1554 de Francisco Cervantes de Salazar” (cap. 17), 
                                explora os diálogos de Cervantes de Salazar (c. 1514-1575) acerca da capital da Nova Espanha. 
                                O historiador observa que, por meio de seus escritos, o humanista toledano forneceu, 
                                além da descrição morfológica da cidade, uma espécie de ordenamento simbólico e retórico dela, em favor de 
                                herdeiros dos conquistadores espanhóis.                                 
                                 </p>
                                
                                 <p className="paragraph-content">
                                 Em suma, <em>A formação de reinos virtuosos (séculos XII ao XVIII)</em> desponta no horizonte dos estudos medievais e modernos como um livro importante, 
                                 ao fornecer um panorama atualizado das pesquisas historiográficas nacionais e internacionais em 
                                 torno das bases consideradas virtuosas que constituíram as monarquias na 
                                 Idade Média e na Moderna.                               
                                 </p>
                                
                                 </div>
                                 </div>



                            <div className="content-columns-artigoII">
                                <div className="column-artigoII">
                                    <h3 id='h3-artigoII'>Projeto</h3>
                                    <p>
                                        » O ensino da fé cristã na península Ibérica, sécs. XIV, XV e XVI (FAPESP n. 17/11111-9). 
                                        Modalidade: Jovem Pesquisador. Pesquisador responsável: Leandro Alves Teodoro (Unesp-Assis)
                                    </p>
                                     
                                </div>
                            </div>
                            <p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>
                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/Instituicoes_fe_e_producao_escrita.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>
                            </div>
                            <footer className="footerDivulgacao">
                                <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
                                <p> Dezembro de 2024</p>
                            </footer>
                        </main>
                    </div>
                    <Footer />
            </div>
            );
    }


export default Instituicoes_fe_e_producao_escrita;


 